import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
// import { Router } from "@reach/router"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"

import { HeadingBasic } from "../../../components/heading/heading"
import { Breadcrumbs } from "../../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../../components/cover-main/cover-main"
import { ProjectsByLocation } from "../../../components/utils/render-functions"

// import ProjectDetail from "../../../templates/project-detail"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const IndustrialPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(
        relativePath: { eq: "commercial/img_commercial-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        propertyType(id: "industrial", idType: SLUG) {
          name
          description
          acfPropertyType {
            image {
              sourceUrl
            }
            excerpt
            banner {
              sourceUrl
            }
          }
          translation(language: ID) {
            name
            description
            acfPropertyType {
              image {
                sourceUrl
              }
              excerpt
              banner {
                sourceUrl
              }
            }
          }
          projects(first: 1000) {
            nodes {
              id
              projectTags {
                edges {
                  node {
                    name
                  }
                }
              }
              title
              slug
              excerpt
              locations {
                nodes {
                  name
                }
              }
              propertyTypes {
                nodes {
                  name
                }
              }
              featuredImage {
                sourceUrl
                caption
                mediaDetails {
                  sizes {
                    name
                    width
                    height
                    file
                    sourceUrl
                  }
                }
              }
              translation(language: ID) {
                # id
                projectTags {
                  edges {
                    node {
                      name
                    }
                  }
                }
                title
                slug
                excerpt
                locations {
                  nodes {
                    name
                  }
                }
                propertyTypes {
                  nodes {
                    name
                  }
                }
                featuredImage {
                  sourceUrl
                  caption
                  mediaDetails {
                    sizes {
                      name
                      width
                      height
                      file
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
        locations {
          nodes {
            slug
            name
          }
        }
      }
    }
  `)

  const wordPress =
    data?.wordPress?.propertyType?.translation || data?.wordPress?.propertyType
  const projects = data.wordPress?.propertyType?.projects?.nodes

  return (
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={wordPress && wordPress.name}
        fbTitle={wordPress && wordPress.name}
        twitterTitle={wordPress && wordPress.name}
        twitterDescription={wordPress && wordPress.description}
        fbDescription={wordPress && wordPress.description}
        description={wordPress && wordPress.description}
        url={location?.href}
        img={
          (wordPress && wordPress.acfPropertyType.banner.sourceUrl) ||
          data.imgCoverMain.childImageSharp.fluid
        }
        twitterImg={
          (wordPress && wordPress.acfPropertyType.banner.sourceUrl) ||
          data.imgCoverMain.childImageSharp.fluid
        }
        img={
          (wordPress && wordPress.acfPropertyType.banner.sourceUrl) ||
          data.imgCoverMain.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      <CoverMain
        h1={wordPress && wordPress.name}
        img={
          (wordPress && wordPress?.acfPropertyType?.banner?.sourceUrl) ||
          data?.imgCoverMain?.childImageSharp?.fluid
        }
        alt={wordPress && wordPress.name}
        className="cover-md cover-detail"
      >
        <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
      </CoverMain>

      <section className="py-main">
        <div className="container container-md">
          <HeadingBasic
            text={[wordPress && wordPress.description]}
            className="mb-0"
          />
        </div>
      </section>

      <ProjectsByLocation
        data={projects}
        location={location.pathname.replace(/\/$/, '')}
        lang="ID"
      />
    </Layout>
  )
}

// const Commercial = ({ uri }) => (
//   <Router basepath="/development/commercial">
//     <IndustrialPage path="/" location={uri} />
//     <ProjectDetail path="/:slug" />
//   </Router>
// )

export default IndustrialPage
